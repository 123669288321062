import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { func, shape, string, bool } from 'prop-types';

import { createResourceLocatorString } from '../../../util/routes';
import { parse } from '../../../util/urlHelpers';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../util/search';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useConfiguration } from '../../../context/configurationContext';
import { ExternalLink } from '../../../components';

import LandingSearchForm from './LandingSearchForm/LandingSearchForm';
import css from './LandingPageBannerSection.module.css';
import Slider from 'react-slick';

const LandingPageBannerSection = props => {
  const { currentSearchParams } = props;
  const config = useConfiguration();
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const location = useLocation();
  const { address, origin, bounds, keywords } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const handleSubmit = values => {
    const searchParams = {
      ...currentSearchParams,
      keywords: values?.keywords,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const topbarSearchInitialValues = () => {
    if (isMainSearchTypeKeywords(config)) {
      return { keywords };
    }

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = isOriginInUse(config)
      ? address && origin && bounds
      : address && bounds;
    return {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };
  };
  const initialSearchFormValues = topbarSearchInitialValues();

  const imageData = [
    {
      image: require('./images/1.jpeg'),
    },
    {
      image: require('./images/2.jpeg'),
    },
    {
      image: require('./images/3.jpeg'),
    },
    {
      image: require('./images/4.jpeg'),
    },
    {
      image: require('./images/5.jpeg'),
    },
    {
      image: require('./images/6.jpeg'),
    },
    {
      image: require('./images/7.jpeg'),
    },
  ];
  const imagesSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 6000,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <section className={css.root}>
      <Slider className={css.sliderProducts} {...imagesSettings}>
        {imageData.map((item, index) => (
          <div key={index}>
            <div className={css.imgBox}>
              {item.image && <img loading="lazy" src={item.image} />}
            </div>
          </div>
        ))}
      </Slider>
      <div className={css.container}>
        <div className={css.bannerTitle}> Find all Live SLP training in one place</div>
        <LandingSearchForm onSubmit={handleSubmit} initialValues={initialSearchFormValues} />
        <div className={css.popularsContainer}>
          <p>Popular:</p>
          <ul>
            <li>
              <ExternalLink
                className={css.popularsItem}
                href="/s?pub_category=basic2DaysFeesCourse"
                targetSelf
              >
                Basic FEES Course
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={css.popularsItem}
                href="/s?pub_category=mentorship"
                targetSelf
              >
                Mentorship
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={css.popularsItem}
                href="s?pub_category=feesCompetencyTraining"
                targetSelf
              >
                Competency training
              </ExternalLink>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

LandingPageBannerSection.defaultProps = {
  rootClassName: null,
  className: null,
  initialSearchFormValues: {},
};

LandingPageBannerSection.propTypes = {
  rootClassName: string,
  className: string,
  history: shape({
    push: func.isRequired,
  }),
  location: shape({
    search: string.isRequired,
  }),
};

export default LandingPageBannerSection;
