import React from 'react';
import { string } from 'prop-types';

import css from './LandingPageSubscribeNewsSection.module.css';

const LandingPageSubscribeNewsSection = props => {
  const {} = props;

  return (
    <section className={css.subscribeNews}>
      <div>
        <div className={css.subscribeContentBox}>
          <p>Subsribe To Our Newsletter</p>
          <p>Stay in touch with us to get latest news and special offers.</p>
        </div>
        <div className={css.subscribeInputContainer}>
          <form onSubmit={() => {}}>
            <input placeholder="E-mail Address" type="email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>
    </section>
  );
};

LandingPageSubscribeNewsSection.defaultProps = {
  rootClassName: null,
  className: null,
};

LandingPageSubscribeNewsSection.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandingPageSubscribeNewsSection;
