import React from 'react';
import { string } from 'prop-types';

import css from './LandingPageContactUsSection.module.css';
import ContactForm from '../../ContactUsPage/ContactForm/ContactForm';

const LandingPageContactUsSection = props => {
  const {} = props;

  return (


      <section className={css.landingPageContactSection}>
        <div>
          <p className={css.title}>Contact Us</p>
          <p className={css.description}>
            We would love to hear from you to discuss our solutions or discuss any special clinical
            challenge that you are facing in your daily routine. If you are interested in endoscopy,
            fiberoptic endoscopic evaluation of swallowing (FEES), high resolution manometry,
            NG-feeding tubes and esophageal introducers it is worth getting in touch.
          </p>
          <ContactForm onSubmit={() => {}} />
        </div>
      </section>
  );
};

LandingPageContactUsSection.defaultProps = {
  rootClassName: null,
  className: null,
};

LandingPageContactUsSection.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandingPageContactUsSection;
