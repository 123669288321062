import React from 'react';
import { compose } from 'redux';
import { string, bool } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import getCountryCodes from '../../../translations/countryCodes';
import { useConfiguration } from '../../../context/configurationContext';
import * as validators from '../../../util/validators';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldPhoneNumberInput,
  FieldSelect,
  FieldCheckbox,
  NamedLink,
} from '../../../components';

import css from './ContactForm.module.css';

const ContactFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, className, formId, handleSubmit, inProgress, intl } = fieldRenderProps;
      const optionalText = intl.formatMessage({
        id: 'StripePaymentAddress.optionalText',
      });
      //Name:
      const firstNameLabel = intl.formatMessage({ id: 'SignupForm.firstNameLabel' });
      //LastName:
      const lastNameLabel = intl.formatMessage({ id: 'SignupForm.lastNameLabel' });
      // Phone
      const phoneLabel = intl.formatMessage(
        { id: 'ContactForm.phoneLabel' },
        { optionalText: optionalText }
      );

      //E-Mail:
      const emailLabel = intl.formatMessage({ id: 'SignupForm.emailLabel' });
      const emailRequiredMessage = intl.formatMessage({ id: 'SignupForm.emailRequired' });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({ id: 'SignupForm.emailInvalid' });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      //Postal code:
      const postalCodeLabel = intl.formatMessage({ id: 'ContactForm.postalCodeLabel' });
      const postalCodePlaceholder = intl.formatMessage({
        id: 'ContactForm.postalCodePlaceholder',
      });
      const postalCodeRequired = validators.required(
        intl.formatMessage({
          id: 'ContactForm.postalCodeRequired',
        })
      );

      //City:
      const cityLabel = intl.formatMessage(
        { id: 'ContactForm.cityLabel' },
        { optionalText: optionalText }
      );
      const cityPlaceholder = intl.formatMessage({ id: 'ContactForm.cityPlaceholder' });

      //State:
      const stateLabel = intl.formatMessage(
        { id: 'ContactForm.stateLabel' },
        { optionalText: optionalText }
      );
      const statePlaceholder = intl.formatMessage({ id: 'ContactForm.statePlaceholder' });

      //Country:
      const countryLabel = intl.formatMessage(
        { id: 'ContactForm.countryLabel' },
        { optionalText: optionalText }
      );
      const countryPlaceholder = intl.formatMessage({
        id: 'ContactForm.countryPlaceholder',
      });

      //TextArea
      const textAreaLegend = intl.formatMessage({ id: 'ContactForm.textAreaLegend' });
      const textAreaLabel = intl.formatMessage(
        { id: 'ContactForm.textAreaLabel' },
        { optionalText: optionalText }
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;
      const config = useConfiguration();
      const countryCodes = getCountryCodes(config.localization.locale);

      return (
        <div className={css.contactSection}>
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.contactFormContainer}>
              <div className={css.formRow}>
                <FieldTextInput
                  className={css.field}
                  type="text"
                  name="firstName"
                  id={formId ? `${formId}.firstName` : 'firstName'}
                  label={firstNameLabel}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'ContactForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.field}
                  type="text"
                  id={formId ? `${formId}.lastName` : 'lastName'}
                  name="lastName"
                  label={lastNameLabel}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'ContactForm.lastNameRequired',
                    })
                  )}
                />
              </div>
              <div className={css.formRow}>
                <FieldTextInput
                  className={css.field}
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  label={emailLabel}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
                <FieldPhoneNumberInput
                  className={css.field}
                  name="phoneNumber"
                  id={'phoneNumber'}
                  label={phoneLabel}
                />
              </div>
              <div className={css.formRow}>
                <FieldTextInput
                  id={formId ? `${formId}.state` : 'state'}
                  name="state"
                  className={css.field}
                  type="text"
                  autoComplete="billing address-level1"
                  label={stateLabel}
                  placeholder={statePlaceholder}
                  onUnmount={() => {}}
                />
                <FieldTextInput
                  id={formId ? `${formId}.postalCode` : 'postalCode'}
                  name="postal"
                  className={css.field}
                  type="text"
                  autoComplete="billing postal-code"
                  label={postalCodeLabel}
                  placeholder={postalCodePlaceholder}
                  validate={postalCodeRequired}
                  onUnmount={() => {}}
                />
              </div>
              <div className={css.formRow}>
                <FieldTextInput
                  id={formId ? `${formId}.city` : 'city'}
                  name="city"
                  className={css.field}
                  type="text"
                  autoComplete="billing address-level2"
                  label={cityLabel}
                  placeholder={cityPlaceholder}
                  onUnmount={() => {}}
                />

                <FieldSelect
                  id={formId ? `${formId}.country` : 'country'}
                  name="country"
                  className={css.field}
                  label={countryLabel}
                >
                  <option disabled value="">
                    {countryPlaceholder}
                  </option>
                  {countryCodes.map(country => {
                    return (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </FieldSelect>
              </div>
              <FieldTextInput
                type="textarea"
                rows="4"
                cols="4"
                description={textAreaLegend}
                id={'message'}
                className={css.textarea}
                name="message"
                label={textAreaLabel}
              />
              <div className={css.acceptPrivacy}>
                <FieldCheckbox
                  className={css.saveForLaterUseCheckbox}
                  textClassName={css.saveForLaterUseLabel}
                  id="acceptPrivacy"
                  name="acceptPrivacy"
                  label=""
                  value="acceptPrivacy"
                />

                <span className={css.saveForLaterUseLegalInfo}>
                  I have read and accept the
                  <NamedLink name="CustomPrivacyPolicyPage"> Privacy Policy</NamedLink>
                </span>
              </div>
              <PrimaryButton
                onClick={() => {
                  handleSubmit();
                }}
                className={classNames(css.contactButton, css.heroButtonFEDelay)}
                type="button"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="ContactForm.submit" />
              </PrimaryButton>
            </div>
          </Form>
        </div>
      );
    }}
  />
);

ContactFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
};

ContactFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const ContactForm = compose(injectIntl)(ContactFormComponent);

export default ContactForm;
