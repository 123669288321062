import React from 'react';
import { string } from 'prop-types';
import Slider from 'react-slick';

import css from './LandingPageSliderProductsSection.module.css';
import { ReviewRating } from '../../../components';

const LandingPageSliderProductsSection = props => {
  const {} = props;
  const productsData = [
    {
      productName: 'PatCom Head Model',
      productDescription: 'Educational and helpful',
      productDescriptionDetail:
        'The PatCom Head Model can be utilized for clinical education and hands on training in the areas of FEES and nasal endoscopy.',
      productBgImg: require('../images/head_model_overview3.png'),
    },
    {
      productName: 'PatCom Endoscope Holder',
      productDescription: 'Handsfree endoscope cleaning and easy portability',
      productDescriptionDetail:
        'The PatCom Endoscope Holder is designed to fully submerge the insertion portion of your endoscope.',
      productBgImg: require('../images/endoscopeHorder.png'),
    },
    {
      productName: 'PatCom Introducer',
      productDescription: 'Reduces patient discomfort',
      productDescriptionDetail:
        'The PatCom Introducer was designed to use visualization capabilities from endoscopes for transnasal.',
      productBgImg: require('../images/patcomInducer.png'),
    },
    {
      productName: 'Reflux Testing',
      productDescription: '24-hour pH and impedance monitoring',
      productDescriptionDetail:
        '24 hour pH monitoring is one of the gold standards in reflux testing. It adds a quantitative measure to the evaluation by detecting pH levels both in the pharynx as well as the distal esophagus. Impedance measurements along the esophagus add additional information about the behavior of acidic reflux as well as non-acidic reflux.',
      productBgImg: require('../images/refluxTesting.webp'),
    },
    {
      productName: 'Videostroboscopy',
      productDescription: 'Excellent image quality',
      productDescriptionDetail:
        'The combination of a HD global shutter camera and portable LED stroboscope provides a high quality image for vocal fold assessment.',
      productBgImg: require('../images/video.png'),
    },
    {
      productName: 'PatCom Workstation',
      productDescription: 'Useful setup for small spaces',
      productDescriptionDetail:
        'The PatCom IV Workstation is designed for use in environments where space is limited, yet a quick and minimal setup is required and can be used as a configuration paired with disposable scopes.',
      productBgImg: require('../images/workStationNew.png'),
    },
    {
      productName: 'PatCom Acute Care System',
      productDescription: 'User-friendly handling',
      productDescriptionDetail:
        'The PatCom Acute Care System is an ideal configuration for performing endoscopy studies in a hospital setting.',
      productBgImg: require('../images/Acute_Care_Cart.png'),
    },
    {
      productName: 'PatCom Portable FEES System',
      productDescription: 'Portable everywhere you go',
      productDescriptionDetail:
        'The PatCom Portable FEES System is the perfect configuration for clinicians needing portability between facilities or patient homes.',
      productBgImg: require('../images/fees.png'),
    },
    {
      productName: 'PatCom Distal Chip Endoscope',
      productDescription: 'Flexible and lightweight',
      productDescriptionDetail:
        'The PatCom Distal Chip Endoscope is best suited for the use in swallowing diagnostics (FEES) and in the ENT field.',
      productBgImg: require('../images/distalEndoscope.png'),
    },
  ];
  const peopleSayData = [
    {
      description:
        'We have had our scope for 3 months now and it is perfect for in office and mobile use. The customer support is amazing! Whenever I have a problem (user error!!) or question, the response is immediate. I highly recommend this company and equipment if you are looking to open a mobile service. The images are clear and the report interface is so easy to use.',
      rating: 5,
      name: 'JULIE COLE',
    },
    {
      description:
        'My private practice purchased our FEES equipment from PatCom Medical and they have been so great to work with! They are always extremely responsive and timely, and very flexible with availability working around our schedule. The equipment has been great and very easy to use! Would 100% recommend them.',
      rating: 5,
      name: 'SARA BETH REDMON',
    },
    {
      description:
        'PatCom has THE BEST customer service. When I encounter a question, I know I will will hear back promptly. Shelby and Christoph are both so helpful and knowledgeable. Highly recommend. The software is very user friendly!',
      rating: 5,
      name: 'SARAH MARRIN',
    },
  ];
  const PeopleSayItem = ({ name, rating, description }) => {
    return (
      <div className={css.peopleSayItem}>
        <ReviewRating rating={rating} />
        <div>
          <p>“{description}”</p>
        </div>
        <p>{name}</p>
      </div>
    );
  };
  const settings = {
    dots: true,
    autoplay: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          autoplay: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const brandImageData = [
    {
      brandImg: require('../../AboutPage/images/idaho.webp'),
    },
    {
      brandImg: require('../../AboutPage/images/westshore.png'),
    },
    {
      brandImg: require('../../AboutPage/images/costal.webp'),
    },
    {
      brandImg: require('../../AboutPage/images/wellness.png'),
    },
    {
      brandImg: require('../../AboutPage/images/sonos.png'),
    },
    {
      brandImg: require('../../AboutPage/images/clarinda.webp'),
    },
  ];
  const brandSettings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 1200,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1056,
        settings: {
          autoplay: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          autoplay: false,
          dots: true,
          arrows: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className={css.sliderProductsSection}>
        <div className={css.sliderContainer}>
          <Slider className={css.sliderProducts} {...settings}>
            {productsData.map((item, index) => (
              <div key={index}>
                <div className={css.container}>
                  <div className={css.imgBox}>
                    {' '}
                    {item.productBgImg && <img src={item.productBgImg} />}
                  </div>
                  <div className={css.contentBox}>
                    <p className={css.teamDisplayName}>{item.productName}</p>
                    <p className={css.teamJobTitle}>{item.productDescription}</p>
                    <p className={css.teamJobTitle}>{item.productDescriptionDetail}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
      <section className={css.peopleSaySection}>
        <div>
          <p className={css.peopleSaySubTitle}>Testimonials</p>
          <p className={css.peopleSayTitle}>What our patients say</p>
          <div className={css.peopleSayBoxContainer}>
            {peopleSayData.map(x => {
              return <PeopleSayItem {...x}></PeopleSayItem>;
            })}
          </div>
          <button className={css.peopleSayLinkButton}>
            <a
              target="_blank"
              href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zLLIrcyxKLYwYLRSNaiwsEwxSU5KTDVNNUs0MjAwtzKosExLskg1Mk42TzMzMjY0SPXiK0gsSc7PVchNTclMTswBAMeqFO0&amp;q=patcom+medical&amp;rlz=1C1CHZN_enCA1023CA1023&amp;oq=patcom+medical&amp;aqs=chrome.1.0i355i512j46i175i199i512j69i59j0i512j0i22i30j69i65l2j69i61.8464j0j7&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x89d4cbae5e6a2007:0x9fb8e23c7f62310e,1,,,,"
            >
              View More
            </a>
          </button>
        </div>
      </section>
      <section className={css.brandSection}>
            <div>
              <Slider className={css.brandSliderContainer} {...brandSettings}>
                {brandImageData.map((item, index) => (
                  <div key={index}>
                    <div className={css.sliderImgBrandContainer}>
                      {item.brandImg && <img src={item.brandImg} />}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </section>
    </>
  );
};

LandingPageSliderProductsSection.defaultProps = {
  rootClassName: null,
  className: null,
};

LandingPageSliderProductsSection.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandingPageSliderProductsSection;
