import React from 'react';
import { string } from 'prop-types';

import { NamedLink } from '../../../components';
import rightImg from '../images/bluecare-doctors-bg1.jpg';

import css from './LandingPagePatentSection.module.css';

const LandingPagePatentSection = props => {
  const {} = props;

  return (
    <>
      <section className={css.root}>
        <div className={css.leftContainer}>
          <p>About clinic</p>
          <p>
            Why patients choose <br /> Us
          </p>
          <p>
            Patient Comfort. It’s our priority. Our equipment is designed <br /> with
            Speech-Language Pathologists in mind.
          </p>
          <div className={css.namedLink}>
          <NamedLink name="AboutPage">About Us</NamedLink>
        </div>        </div>
        <div className={css.rightContainer}>
          <img src={rightImg} />
        </div>
      </section>
      <section className={css.rootTwo}>
        <div>
          <div className={css.contentText}>
            <span style={{color:'#1e3265' , fontWeight:700}}>PatCom Medical</span>  was founded to provide clinicians and practitioners with the latest
            technology at competitive pricing, all while keeping the patient at the center of all we
            do. This goal plays a major role in the development and selection of our products.
          </div>
          <div className={css.contentContainer}>
            <div className={css.contentBox}></div>
            <div className={css.contentBox}></div>
            <div className={css.contentBox}></div>
            <div className={css.contentBox}></div>
          </div>
        </div>
      </section>
    </>
  );
};

LandingPagePatentSection.defaultProps = {
  rootClassName: null,
  className: null,
};

LandingPagePatentSection.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandingPagePatentSection;
