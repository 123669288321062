import React from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, Field } from 'react-final-form';

import { intlShape, injectIntl } from '../../../../util/reactIntl';
import { Form } from '../../../../components';

import css from './LandingSearchForm.module.css';
import IconSearchDesktop from '../../../../components/Topbar/TopbarSearchForm/IconSearchDesktop';

const LandingSearchFormComponent = props => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const { rootClassName, className, intl, inputRef, values } = formRenderProps;
        const classes = classNames(rootClassName || css.root, className);

        return (
          <Form
            className={classes}
            onSubmit={() => {
              props.onSubmit(values);
            }}
          >
            <Field
              name="keywords"
              render={({ input }) => {
                return (
                  <input
                    className={isMobile ? css.mobileInput : css.desktopInput}
                    {...input}
                    id={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
                    data-testid={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
                    ref={inputRef}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'TopbarSearchForm.searchPlaceholder',
                    })}
                    autoComplete="off"
                  />
                );
              }}
            />
            <button className={css.searchSubmit}>
              <IconSearchDesktop />
            </button>
          </Form>
        );
      }}
    />
  );
};

LandingSearchFormComponent.defaultProps = { rootClassName: null, className: null };

LandingSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const LandingSearchForm = injectIntl(LandingSearchFormComponent);

export default LandingSearchForm;
