import React from 'react';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './LandingPageSocialMediaSection.module.css';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

const LandingPageSocialMediaSection = props => {
  const {} = props;

  return (
    <section className={css.socialMediaSection}>
      <div>
        <div className={css.titleContainer}>
          <FontAwesomeIcon color='red' size='2xl' icon={faYoutube} />
          <p>Follow us on Social Media</p>
        </div>
        <div className={css.socialItems}>
          <iframe
            src="https://www.youtube.com/embed/MWinO7UiXu8?si=S-QvRGE_LPJqIYlG"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/hEfuPMujVns?si=T23myT_LXL7lVq4N"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/sd2aX68lFtA?si=BArlDJKU-r0m2h9r"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/gRcAWxxDY2U?si=2SYbNi5sORrgoo8P"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/uqnHrn3iHJY?si=aQqjLOkz0sONDv4O"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/PO-gMyu5ySo?si=cQdTxw_6CZPZOZKV"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/VLoITOfZ-eo?si=qqw-cfNZE0rIAyp3"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/MWinO7UiXu8?si=bon2KSkmQOJNGbc6"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/YgIKm1bk2yw?si=DfDXbqM9zV7jZhC6"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/uii718QoQno?si=Hd9MCQcxvVB9tmLN"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/3dcH3T31nWQ?si=MMLDZ10JVdsuwHdo"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};

LandingPageSocialMediaSection.defaultProps = {
  rootClassName: null,
  className: null,
};

LandingPageSocialMediaSection.propTypes = {
  rootClassName: string,
  className: string,
};

export default LandingPageSocialMediaSection;
